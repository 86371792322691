<template>
  <div>
    <div class="osk-overlay" @click.stop></div>
    <div
      class="osk-keyboard"
      id="osk-keyboard"
      @keyup.enter="handleEnter"
      @keyup.esc="closeKeyboard"
      tabindex="0"
      ref="keyboardRef"
    >
      <div v-if="title" class="osk-title">{{ title }}</div>
      <input
        id="oskInputId"
        class="osk-inputarea"
        type="text"
        v-model="inputValue"
        @input="onInput"
        ref="oskInputRef"
        :maxlength="maxLength > 0 ? maxLength : null"
      />
      <div class="osk-container">
        <div class="osk-row">
          <button v-for="key in keyLayout.numbers" :key="key" class="osk-key" @click="handleKeyPress(key)">
            {{ key }}
          </button>
          <button class="osk-key osk-key-func osk-key-backspace" @click="handleBackspace">
             ⌫ Back
          </button>
        </div>
        <div class="osk-row">
          <button v-for="key in keyLayout.row1" :key="key" class="osk-key" @click="handleKeyPress(key)">
            {{ isShiftActive ? key.toUpperCase() : key }}
          </button>
        </div>
        <div class="osk-row osk-row-indent">
          <button v-for="key in keyLayout.row2" :key="key" class="osk-key" @click="handleKeyPress(key)">
             {{ isShiftActive ? key.toUpperCase() : key }}
          </button>
        </div>
        <div class="osk-row">
           <button class="osk-key osk-key-func osk-key-shift" :class="{ 'osk-key-shift-active': isShiftActive }" @click="toggleShift">
             ⇧ Shift
           </button>
           <button v-for="key in keyLayout.row3" :key="key" class="osk-key" @click="handleKeyPress(key)">
             {{ isShiftActive ? key.toUpperCase() : key }}
           </button>
             <button class="osk-key" @click="handleKeyPress(',')">,</button>
           <button class="osk-key" @click="handleKeyPress('.')">.</button>
        </div>
        <div class="osk-row">
            <button class="osk-key osk-key-func osk-key-clear" @click="handleClear">Clear</button>
            <button class="osk-key osk-key-space" @click="handleKeyPress(' ')">Space</button>
            <button class="osk-key osk-key-func osk-key-enter" @click="handleEnter">Enter</button>
        </div>
          <div class="osk-row osk-row-symbols">
             <button class="osk-key" @click="handleKeyPress('?')">?</button>
             <button class="osk-key" @click="handleKeyPress('!')">!</button>
             <button class="osk-key" @click="handleKeyPress('-')">-</button>
             <button class="osk-key" @click="handleKeyPress('_')">_</button>
             <button class="osk-key" @click="handleKeyPress('@')">@</button>
             <button class="osk-key" @click="handleKeyPress('#')">#</button>
             <button class="osk-key" @click="handleKeyPress('$')">$</button>
             <button class="osk-key" @click="handleKeyPress('&')">&</button>
             <button class="osk-key" @click="handleKeyPress('\'')">'</button>
              <button class="osk-key" @click="handleKeyPress('&quot;')">"</button>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnScreenKeyboard',
  props: {
    title: { type: String, default: null },
    initialValue: { type: String, default: "" },
    maxLength: { type: Number, default: 0 } // 0 or less means no limit
  },
  data() {
    return {
      inputValue: this.initialValue,
      isShiftActive: false,
      keyLayout: {
         numbers: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
         row1: ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
         row2: ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
         row3: ['z', 'x', 'c', 'v', 'b', 'n', 'm']
      }
    }
  },
  mounted() {
    this.setFocus();
    // Add listener for physical keyboard events if needed
    // window.addEventListener('keydown', this.handlePhysicalKeydown);
  },
  // beforeDestroy() {
  // Remove listener if added
  //   window.removeEventListener('keydown', this.handlePhysicalKeydown);
  // },
  activated() {
    // Reset state if component is reused (e.g., keep-alive)
    this.inputValue = this.initialValue;
    this.isShiftActive = false;
    this.setFocus();
  },
  methods: {
    setFocus() {
       // Focus the main keyboard container first to capture Esc/Enter
       this.$nextTick(() => {
        if (this.$refs.keyboardRef) {
           this.$refs.keyboardRef.focus();
        }
        // Optionally focus the input field itself, though interaction
        // primarily happens via buttons. Focus container might be better.
        if (this.$refs.oskInputRef) {
          this.$refs.oskInputRef.focus();
        }
      });
    },
    handleKeyPress(key) {
      if (this.maxLength > 0 && this.inputValue.length >= this.maxLength) {
        return; // Enforce maxLength
      }

      const charToAdd = this.isShiftActive ? key.toUpperCase() : key.toLowerCase();
      this.inputValue += charToAdd;

      // If shift was active for one character, turn it off
      if (this.isShiftActive) {
          // Optional: Turn shift off after one key press
          this.isShiftActive = false;
      }
      this.$emit('input', this.inputValue); // Emit input event for v-model compatibility
      this.setFocus(); // Keep focus on the keyboard
    },
    handleBackspace() {
      if (this.inputValue.length > 0) {
        this.inputValue = this.inputValue.slice(0, -1);
        this.$emit('input', this.inputValue); // Emit updated value
      }
       this.setFocus();
    },
    handleClear() {
        const hadValue = this.inputValue.length > 0;
        this.inputValue = "";
        if (hadValue) {
             this.$emit('clear'); // Emit clear event
        } else {
             this.closeKeyboard(); // If already empty, treat second clear as cancel
        }
        this.$emit('input', this.inputValue); // Emit empty value
        this.setFocus();
    },
    toggleShift() {
      this.isShiftActive = !this.isShiftActive;
      this.setFocus();
    },
    handleEnter() {
    console.log("standardKeyboard handleEnter() ", this.inputValue );
      this.$emit('click', this.inputValue);
      // Optionally clear input after enter:
      // this.inputValue = "";
      // this.$emit('input', this.inputValue);
       this.setFocus();
    },
    onInput() {
       // Handles direct input typing IF the input field itself gets focus
       // and ensures the parent component knows about the change.
      //  this.$emit('input', this.inputValue);
    },
    closeKeyboard() {
        this.$emit('cancel'); // Emit cancel event
    },

    // Optional: Handle physical keyboard input if needed
    handlePhysicalKeydown(event) {
      event.preventDefault(); // Prevent default browser behavior if needed
      const key = event.key;

      if (key === 'Enter') {
        this.handleEnter();
      } else if (key === 'Escape') {
        this.closeKeyboard();
      } else if (key === 'Backspace') {
        this.handleBackspace();
      } else if (key === 'Shift') {
         // Simple toggle on physical shift press, might need refinement
         // for hold behavior if desired.
        this.toggleShift();
      } else if (key.length === 1) { // Handle printable characters
        // Check against allowed characters if necessary
        this.handleKeyPress(key);
      }
    }
  }
}
</script>

<style lang="scss">
// --- Reuse or adapt base styles from your keypad ---
.osk-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  z-index: 15;
  animation: fadeIn 0.3s ease-in; // Reusing animation
}

.osk-keyboard {
  position: fixed;
  bottom: 0; // Position at bottom for mobile feel
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 8px; // Slightly less padding
  border-radius: 8px 8px 0 0; // Rounded top corners
  border: 2px solid darkolivegreen; // Slightly thinner border
  border-bottom: none;
  background: #eee;
  color: #333; // Darker text color
  box-shadow: 0 -4px 8px rgba(0,0,0,0.15);
  width: 100%; // Full width on mobile
  max-width: 800px; // Max width for larger screens
  z-index: 16;
  animation: slideUpFromBottom 0.3s ease-out; // New animation
  outline: none; // Remove focus outline from container

  // Desktop centering (override bottom positioning)
  @media (min-width: 600px) {
     top: 50%;
     bottom: auto;
     transform: translate(-50%, -50%);
     border-radius: 8px; // All corners rounded
     border: 3px solid darkolivegreen; // Restore border thickness
     width: 90%;
     animation: slideUp 0.3s ease-out; // Reuse original slideUp
  }
}

// --- Animations (Keep fadeIn, slideUp, add slideUpFromBottom) ---
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp { // For Desktop
  from { transform: translate(-50%, -40%); opacity: 0; }
  to { transform: translate(-50%, -50%); opacity: 1; }
}

@keyframes slideUpFromBottom { // For Mobile
    from { transform: translate(-50%, 100%); opacity: 0; }
    to { transform: translate(-50%, 0%); opacity: 1; }
}

// --- Title and Input Area ---
.osk-title {
  text-align: center;
  background: darkolivegreen;
  color: white;
  font-size: clamp(14px, 3vw, 18px);
  padding: 5px;
  border-radius: 4px 4px 0 0; // Match container top
  margin: -8px -8px 8px -8px; // Adjust to fill padding space

   @media (min-width: 600px) {
      font-size: clamp(16px, 4vw, 24px);
       border-radius: 4px; // All corners rounded on desktop
       margin-bottom: 10px;
   }
}

.osk-inputarea {
  width: 100%;
  font-size: clamp(16px, 4vw, 20px);
  font-weight: 500;
  text-align: left; // Left align text input
  padding: 8px 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; // Include padding/border in width

   @media (min-width: 600px) {
        font-size: clamp(18px, 5vw, 24px);
        padding: 10px;
   }
}

// --- Keyboard Layout & Keys ---
.osk-container {
  display: flex;
  flex-direction: column;
  gap: 5px; // Space between rows
}

.osk-row {
  display: flex;
  justify-content: center;
  gap: 5px; // Space between keys in a row

  // Slight indent for ASDF row for classic look
  &.osk-row-indent {
       padding: 0 2.5%; // Indent by percentage
  }

  // Smaller gap for symbols row maybe
   &.osk-row-symbols {
      gap: 3px;
   }
}

.osk-key {
  flex: 1 1 0px; // Allow keys to grow and shrink evenly
  min-width: 28px; // Minimum key width
  height: clamp(35px, 9vw, 45px);
  font-weight: 500;
  font-size: clamp(14px, 3.5vw, 18px);
  text-align: center;
  background: #f9f9f9; // Lighter key background
  color: #333;
  border: 1px solid #bbb;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.15s, box-shadow 0.15s;
  display: flex; // For centering content vertically
  justify-content: center;
  align-items: center;
  padding: 0 2px; // Small horizontal padding

  &:hover {
    background: #e0e0e0;
    border-color: #aaa;
  }
  &:active {
    background: #c5c5c5;
    box-shadow: inset 0 1px 3px rgba(0,0,0,0.2);
  }

   @media (min-width: 600px) {
        height: clamp(40px, 5vw, 50px);
        font-size: clamp(16px, 2.5vw, 20px);
        min-width: 40px;
   }
}

// --- Special Key Styling ---
.osk-key-func {
  background: #d8d8d8; // Slightly different bg for function keys
  font-weight: 600;
   flex-grow: 1.5; // Make function keys slightly wider by default

   @media (min-width: 600px) {
        font-size: clamp(14px, 2vw, 18px);
   }
}

.osk-key-backspace {
    flex-grow: 2.5; // Wider backspace
    background: #e9a6a6; // Reddish tint
    &:hover { background: #e08a8a; }
    &:active { background: #d76e6e; }
}

.osk-key-shift {
    flex-grow: 2; // Wider shift
    background: #c8d4e3; // Bluish tint
     &:hover { background: #b4c6dc; }
     &:active { background: #a0b8d5; }

    &.osk-key-shift-active {
        background: #a0b8d5;
        box-shadow: inset 0 1px 3px rgba(0,0,0,0.3);
        color: white;
    }
}

.osk-key-space {
    flex-grow: 10; // Much wider spacebar
    background: #f0f0f0;
}

.osk-key-clear {
    flex-grow: 2.5;
    background: #f0ad4e; // Orangey tint
    color: white;
     &:hover { background: #ec9b3b; }
     &:active { background: #e88a28; }
}

.osk-key-enter {
    flex-grow: 2.5;
    background: #5cb85c; // Greenish tint
    color: white;
     &:hover { background: #4cae4c; }
     &:active { background: #449d44; }
}

</style>