<template>
  <div>
    <div class="ts-keypad-overlay" @click.stop></div>
    <div 
      class="ts-keypad" 
      id="ts-keyboard"
      @keyup.enter="enter()"
      @keyup.esc="clear()"
      tabindex="0"
    >
      <div v-if="title" class="ts-keypad-title">{{title}}</div>
      <input 
        id="keypadInputId" 
        class="ts-keypad-inputarea" 
        type="text" 
        v-model="keypadInput" 
        @input="verifyInput"
        ref="keypadInputRef"
      />
      <div class="ts-keypad-container">
        <div class="ts-keypad-keysarea">
          <div class="number-grid">
            <button class="ts-single" @click="keyPress('7')">7</button>
            <button class="ts-single" @click="keyPress('8')">8</button>
            <button class="ts-single" @click="keyPress('9')">9</button>
            <button class="ts-single" @click="keyPress('4')">4</button>
            <button class="ts-single" @click="keyPress('5')">5</button>
            <button class="ts-single" @click="keyPress('6')">6</button>
            <button class="ts-single" @click="keyPress('1')">1</button>
            <button class="ts-single" @click="keyPress('2')">2</button>
            <button class="ts-single" @click="keyPress('3')">3</button>
            <div class="ts-empty"></div>
            <button class="ts-single" @click="keyPress('0')">0</button>
            <button class="ts-single" @click="keyPress('.')">{{isShowDecimal ? "." : ""}}</button>
          </div>
        </div>
        <div class="ts-keypad-functionsarea">
          <button class="ts-f-double clear-btn" @click="clear()">Clear</button>
          <button class="ts-f-double enter-btn" @click="enter()">ENTER</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'keypad',
  props: {
    title: { type: String, default: null },
    isShowDecimal: { type: Boolean, default: true },
    decimalPlaces: { type: Number, default: 2 },
    minValue: { type: Number, default: 0 },
    maxValue: { type: Number, default: 9999.99 },
    maxLen: { type: Number, default: 6 },
    backgroundColor: { type: String, default: "" }
  },
  data() {
    return {
      keypadInput: "",
      isActive: false
    }
  },
  mounted() {
    this.isActive = true;
    this.setFocus();
  },
  activated() {
    this.keypadInput = "";
    this.isActive = true;
    this.setFocus();
  },
  deactivated() {
    this.isActive = false;
  },
  methods: {
    setFocus() {

      this.$nextTick(() => {
        if (this.$refs.keypadInputRef) {
          this.$refs.keypadInputRef.focus();
          console.log( "hit setFocus()");
        }
      });
      // this.$nextTick(() => {
      //   const input = this.$refs.keypadInputRef;
      //   if (input) {
      //     input.focus();
      //     const length = input.value.length;
      //     input.setSelectionRange(length, length);
      //   }
      // });
    },
    keyPress(e) {
      if (this.keypadInput.length >= this.maxLen) {
        return;
      }
      this.keypadInput += e;
      this.setFocus();
    },
    clear() {
      if (this.keypadInput.length <= 0) {
        this.$emit("cancel");
      } else {
        this.keypadInput = "";
      }
      this.setFocus();
    },
    verifyInput() {
      if (this.keypadInput.length >= this.maxLen) {
        this.keypadInput = this.keypadInput.substring(0, this.maxLen);
      }
    },
    enter() {
      if (!this.keypadInput) return; // Prevent empty enter
      
      let value = this.keypadInput;
      this.keypadInput = ""; // Clear immediately
      if (value.indexOf(".") < 0) {
        value = (parseFloat(value) / 100).toFixed(this.decimalPlaces).toString();
      } else {
        value = parseFloat(value).toFixed(this.decimalPlaces).toString();
      }
      this.$emit("click", value);
      this.setFocus();
    }
  }
}
</script>

<style lang="scss">
.ts-keypad-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  z-index: 15;
  animation: fadeIn 0.3s ease-in;
}

.ts-keypad {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 10px;
  border-radius: 8px;
  border: 3px solid darkolivegreen;
  background: #eee;
  color: gray;
  box-shadow: 0 0 8px 5px rgba(0,0,0,0.2);
  width: 90%;
  max-width: 360px;
  min-height: 300px;
  z-index: 16;
  animation: slideUp 0.3s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { transform: translate(-50%, -40%); opacity: 0; }
  to { transform: translate(-50%, -50%); opacity: 1; }
}

.ts-keypad-title {
  text-align: center;
  background: darkolivegreen;
  color: white;
  font-size: clamp(16px, 4vw, 24px);
  padding: 5px;
}

.ts-keypad-inputarea {
  width: 100%;
  font-size: clamp(18px, 5vw, 24px);
  font-weight: 500;
  text-align: right;
  padding: 5px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.ts-keypad-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.ts-keypad-keysarea {
  flex: 2;
  min-width: 150px;
}

.number-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding: 5px;
}

.ts-single {
  height: clamp(40px, 12vw, 54px);
  font-weight: 600;
  font-size: clamp(20px, 6vw, 36px);
  text-align: center;
  background: darkolivegreen;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s;
}

.ts-single:hover {
  background: #556B2F;
}

.ts-empty {
  background: none;
}

.ts-keypad-functionsarea {
  flex: 1;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
}

.ts-f-double {
  border-radius: 1em;
  height: clamp(50px, 15vw, 64px);
  font-weight: 500;
  font-size: clamp(16px, 4vw, 24px);
  text-align: center;
  border: none;
  cursor: pointer;
  transition: background 0.2s;
}

.clear-btn {
  background: firebrick;
  color: white;
}

.clear-btn:hover {
  background: #8B0000;
}

.enter-btn {
  background: darkgreen;
  color: white;
}

.enter-btn:hover {
  background: #006400;
}

@media (max-width: 480px) {
  .ts-keypad {
    padding: 5px;
  }
  
  .ts-keypad-container {
    flex-direction: column;
  }
  
  .number-grid {
    gap: 5px;
  }
}
</style>