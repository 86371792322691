<template>
  <div class="order-receipt-container " id="section-to-print">
    <div  class="receipt-header-section">
      <h2 class="title" @click="toggleDetail()">{{prompts.title}}</h2>
    </div>
    <div id="order-receipt" class="order-receipt" v-if="orderDetails && orderDetails.items ">
      <div class="item-list-container p-2" id="order-receipt-listing">
        <div v-if="header">
          <div v-for="(line,index) in header" :key="index" class="receipt-header">
            <span>{{line}}</span>
          </div>
        </div>
        <div   v-for="(item, index) in orderItems" :key="index" class="item-details pt-1 pb-0" >
          <div class="row" :class="(item.selected) ? 'item-selected' : ''"  @click="itemSelect(item)">
            <div class="col-12">
              <!-- Trying to get this to be:       1 Item Description      1.23
                  all on one line, normal         12 Another Item         18.75
                  line spacing                   234 Even another Item   345.67
                                                     Payment On Account  205.00
                                                      Acct: 12345
                                                      Mr. Smith
                                                      Ref: 1232323232232
              -->
              <div class="item-title">
                <span v-if="item.itemType==='item'" style="width:2em;text-align:right;float:left;padding-right:.5em;">{{ item.qty }}</span>
                <span v-else style="padding-left:2em;"></span>  <!-- Need to keep items and non-items (tenders) aligned on the text (see example above) -->
                <span>{{ item.title }}</span>
              </div>
              <div class="item-price text-right">
                {{ currency(Math.abs(item.qty) * item.price,{ separator: ',' }).multiply( item.itemType==='tender'? -1 : 1 ).format()}}<span style="font-size:.8em;">{{(item.taxable) ? 'T' : ''}}</span><span style="font-size:.8em">{{(item.void) ? 'V' : ''}}</span>
              </div>
            </div>
            <div v-if="item.itemType==='item' && item.options.openItem && item.qty != 1" class="col-12 item-description">
               {{ item.qty}} @ {{ currency(item.price).format()}}
            </div>
            <div v-if="showDescription" class="col-12 item-description">
               {{ item.description }}
            </div>
            <div class="col-12">
              <div v-if="item.reference">
                <div v-for="(reference,rindex) in item.reference" :key="rindex">
                  <span style="padding-left:4em;">{{reference}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="receipt-totals-section" class="receipt-totals-section  ">
        <hr />
        <table class="w-100">
          <tr>
            <td align="right">{{prompts.items}} <span style="font-weight:1000;">({{orderDetails.numberItems}})</span></td>
            <td></td>
          </tr>
          <tr>
            <td align="right">{{prompts.subTotal}}</td>
            <td align="right">{{ currency(orderDetails.subTotal,{ separator: ',' }).format() }}</td>
          </tr>
          <tr v-if="isVAT">
            <td align="right">{{prompts.taxItemizer[0]}}</td>
            <td align="right">{{ currency(orderDetails.taxable,{ separator: ',' }).format() }}</td>
          </tr>
          <tr v-if="!isVAT && consolidateTax">
            <td align="right">{{prompts.taxItemizer[0]}}</td>
            <td align="right">{{ currency(orderDetails.tax.reduce((a, b) => a + b, 0),{ separator: ',' }).format() }}</td>
          </tr>
          <template v-if="!isVAT && !consolidateTax" >
            <tr v-for="(taxItemizer,i) in orderDetails.tax" :key="i">
              <template v-if="taxItemizer !== 0">
                <td align="right">{{prompts.taxItemizer[i]}}</td>
                <td align="right">{{ currency(taxItemizer,{ separator: ',' }).format() }}</td>
              </template>
            </tr>
          </template>
          <tr>
            <td align="right">{{prompts.total}}</td>
            <td align="right">{{ currency(orderDetails.total,{ separator: ',' }).format() }}</td>
          </tr>
          <template v-if="orderDetails.amountPaid !== 0">
            <tr>
              <td align="right">{{prompts.amountPaid}}</td>
              <td align="right">{{ currency(orderDetails.amountPaid,{ separator: ',' }).format() }}</td>
            </tr>
            <tr>
              <td align="right">{{prompts.balanceDue}}</td>
              <td align="right">{{ currency(orderDetails.total,{ separator: ',' }).subtract( orderDetails.amountPaid).format() }}</td>
            </tr>
          </template>
        </table>
      </div>
      <!--
        We will likely AUTOMATICALLY print a receipt if the tender option is set to auto print
        This option should NOT be enabled until the check is paid as this will be an opening for fraud
       -->
      <!-- <div id="print-Receipt" v-if="enablePrint" class="print-copy py-3" >
        <button class="print-btn" @click="printReceipt"><i class="fa fa-print"></i> {{prompts.printReceipt}}</button>
      </div> -->
    </div>

  </div>
</template>

<script>
import Currency from 'currency.js'
import scrollToBottom from './receipt-order-auto-scroll.js'
// import _ from 'lodash'

export default {
  props: {
    orderDetails   : { type: Object, default: () => {} },
    isVAT          : { type: Boolean, default: false },
    consolidateTax : { type: Boolean, default: false },
    enablePrint    : { type: Boolean, default: false },
    header         : { type: Array, default: () => [] },
    footer         : { type: Array, default: () => [] },
    showDescription: { type: Boolean, default: true },
    sortReceipt    : { type: Boolean, default: false },
    prompts        : {
      type: Object,
      default: () => {
        return {
          items                   : '# Items',
          printReceipt            : 'Print Receipt',
          title                   : 'Receipt',
          subTotal                : 'Sub Total',
          taxItemizer             : ['Tax', 'Tax2', 'Tax3', 'Tax4'],
          total                   : 'Total',
          amountPaid              : 'Amount Paid',
          balanceDue              : 'Balance Due',
          isSetReceiptHeightOnLoad: false                             // will check only initize with set interval method
        }
      }
    }
  },
  mounted () {

    window.addEventListener('resize', this.updateReceiptHeight)
    const interval = setInterval(() => {
      if (!this.isSetReceiptHeightOnLoad) {
        this.updateReceiptHeight()
      } else {
        clearInterval(interval)
      }
    }, 1)
  },
  activated () {
    // this._
  },
  data() {
    return {
      lastListSize: 0
    }
  },
  watch: {
    orderDetails: {     // only scroll if we're adding or removing something - this allows the "select" options to NOT shift the list
      handler: function () {    
        if( this.orderDetails.items.length <= this.lastListSize ) {    
          this.lastListSize = this.orderDetails.items.length;
          return;
        }
        this.lastListSize = this.orderDetails.items.length;
        scrollToBottom();
      },
      deep: true
    }
  },
  computed:{
    orderItems () {
      if (this.sortReceipt) {
        return _.orderBy(this.orderDetails.items, ['sequence', 'itemNumber'], ['asc'])
      } else {
        return this.orderDetails.items
      }
    }
  },
  methods: {
    onChange(event) {
      console.log( "receipt changed" ,  event );
    },
    toggleDetail() {
      this.$emit( "detail" );
    },
    updateReceiptHeight (event) {
      if (document.getElementById('footer-actions') && document.getElementById('receipt-totals-section')) {
        var category = document.querySelector('#navigation-bar ul.nav.nav-tabs') ? document.querySelector('#navigation-bar ul.nav.nav-tabs').offsetHeight : 0
        var payment = document.getElementById('payment-heading-container') ? document.getElementById('payment-heading-container').offsetHeight : 0
        var printingReceipt = document.getElementById('print-Receipt') ? document.getElementById('print-Receipt').offsetHeight : 0
        var footerSection = document.getElementById('footer-actions').offsetHeight
        var priceSection = document.getElementById('receipt-totals-section').offsetHeight
        var receipt = document.getElementById('order-receipt-listing')
        receipt.style.height = (window.innerHeight - ((category + payment + printingReceipt + footerSection + priceSection) * 1.5)) + 'px'
        this.isSetReceiptHeightOnLoad = true
      }
    },
    itemSelect( item ) {
      if( item.selected == true) {
        item.selected = false
        this.$emit( 'unselect' , item );
      } else {
        item.selected = true;
        this.$emit( 'select' , item );
      }

    },
    currency (c) {
      return Currency(c)
    },
    printReceipt () {
      // let r = document.getElementById("order-receipt");
      // console.log("found" , r );

      this.$emit( "print" );

      // window.print()
    }
  }
}
</script>
<style>
#modal-receipt___BV_modal_outer_ {
    z-index: 10 !important;
  }
</style>
<style lang="scss" scoped>

  .item-selected {
    background:lightgray;
    margin: 0 .25em 0 .25em;
    border-radius:.5em;
    // background:red;
  }

  .receipt-header-section {
    // background:cyan;
  }
  .receipt-totals-section {
    // background:lime;
    padding: 0 2em 2em 0;
    font-weight: 600;
  }

  .order-receipt-container {
    color:gray;
    font-size: 14px;
    border: 1px solid #659dbd;
    border-radius:1em;
    margin-top:1em;
    height:100%;
    h2.title {
      font-size: 18px;
      text-align: center;
      color: #659dbd;
      position: relative;
      border-bottom:1px solid lightgray;
      // &:after {
      //   height: 1px;
      //   width: 80%;
      //   content: "";
      //   position: absolute;
      //   bottom: -8px;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   background: -moz-linear-gradient(left,  rgba(255,255,255,0.67) 0%, rgba(101,157,189,0.67) 26%, rgba(101,157,189,0.67) 74%, rgba(249,251,252,0.67) 99%, rgba(255,255,255,0.67) 100%); /* FF3.6-15 */
      //   background: -webkit-linear-gradient(left,  rgba(255,255,255,0.67) 0%,rgba(101,157,189,0.67) 26%,rgba(101,157,189,0.67) 74%,rgba(249,251,252,0.67) 99%,rgba(255,255,255,0.67) 100%); /* Chrome10-25,Safari5.1-6 */
      //   background: linear-gradient(to right,  rgba(255,255,255,0.67) 0%,rgba(101,157,189,0.67) 26%,rgba(101,157,189,0.67) 74%,rgba(249,251,252,0.67) 99%,rgba(255,255,255,0.67) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      //   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#abffff', endColorstr='#abffff',GradientType=1 ); /* IE6-9 */
      // }
    }
    .order-receipt {
      .item-list-container {
        // min-height: 400px;
        // max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;

        .receipt-header {
          text-align:center;
          // font-style:bold;
          font-size:16px;
        }
        .item-details {
          // border-bottom: 1px solid #ccc;
          .item-title {
            float: left;
            font-weight:600;
            width: calc(100% - 54px);
          }
          .item-price {
            float: left;
            width: 54px;
            text-align: right;
            font-size: 12px;
          }

          .item-description {
            font-weight:400;
            padding-left: 4em;
            padding-right: 5em;
            white-space: pre-line;
          }
        }
      }
      .print-copy {
        background: #659dbd;
        text-align: center;
        .print-btn {
          background: none;
          border: none;
          outline: none !important;
          color: #fff !important;
        }
      }
    }
  }
</style>
<!-- Two STYLE sections required for the print; one scoped, one not -->
<style>
 @media print {
    body * {
      visibility: hidden;
    }
    .non-printable {
      display: none;
    }

    #section-to-print, #section-to-print * {
      visibility: visible;
    }
    #section-to-print {
      min-width: 600px;
      width:100%;
      height:100%;
      page-break-after:always;
    }
    #section-to-print .item-qty {
      float: left;
      width: 70%;
    }
    #section-to-print .item-title {
      float: left;
      width: 70%;
    }
    #section-to-print .item-details {
      clear: both;
    }
    #section-to-print .print-copy {
      display: none;
    }
    #section-to-print .item-list-container {
      max-height: 100% !important;
      overflow: auto !important;
    }
  }
</style>
