<template>
  <div
    class="menu-item-container"
    :class="{
      'item-selected': item.qty !== 0,
      'max-quantity-reached': item.qty >= item.maxChoice
    }"
    @click="select(item)"
  >
    <img :src="item.pictureUrl" class="w-100 item-img" alt="" />
    <div class="item-title" :style="(item.title.length>20) ? (item.title.length>30) ? 'font-size:.85em;' : 'font-size:1em;' : ''">
      {{item.title}}
    </div>
    <div class="item-price">{{ (item.options.openItem) ? 'OPEN' :  parseFloat(item.price[priceLevel]).toFixed(2) }}</div>
    <div class="overlay" :class="item.itemStyle"></div>

    <div v-if="item.qty > 0" class="trash-icon show-hand" @click.stop="removeOne(item)">
      <i class="fa fa-trash" />
    </div>

    <div class="qty-wrapper" v-if="item.qty > 0">
        <div class="item-qty-display">{{item.qty}}</div>

        <div class="max-qty-overlay" v-if="item.qty >= item.maxChoice">
            <span>max</span>
        </div>
    </div>

    </div>
</template>

<script>

// import Keypad from "@/components/Keypad";
// import Keypad from "vue-numkeyboard"

export default {
  name: 'ts-menuItem',
  components: {
    // Keypad
  },
  props: {
    item: {type: Object, default: () => {}},
    priceLevel: { type: Number, default: 0 },
    backgroundColor: { type: String, default: "" }
  },
  data () {
    return {
      showKeyboard: false
    }
  },
  mounted () {

  },
  methods: {
    select( item ) {

      // if( item.options.openItem ) {
      //   if( ! this.showKeyboard ) {
      //     console.log( "showing keyboard" );
      //     this.showKeyboard = true;
      //     return;
      //   }
      // }

      this.showKeyboard = false;              // ensure off, always
      item.priceLevel = this.priceLevel;      // what we are taking in here
      this.$emit( "select", item );
    },
    removeOne( item ) {
      this.$emit("removeOne", item );
    }

  }
}
</script>
<style lang="scss"  >

.menu-item-container {
  border-radius:.75em;
  position: relative;
  height: 110px;
  overflow: hidden;
  transition: background 2s ease;    // 14-Jun-21 wjs :: Dont think this is working

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background: rgba(6,49,122,.8);
    background: rgba(64,0,64,.8); //
    // background:rgba(51,54 ,82,.8);

    // transition: background .5s ease;
  }

  .aqua {
    background:  rgba(00,255,255,.8);
  }

  .purple {
    //background: #3b3b76;
    background: rgba(66,95,145,.8); //
    //background: rgba(6,49,122,.8);
  }

  .blue {
    //background: #3b3b76;
    //background: rgba(64,0,64,.8); //
    background: rgba(6,49,122,.8);
    //background: rgba(6,49,122,.8);
  }

  .coral {
    background: rgba(155,127,80,.8);
  }

  .green {
    //background: #3b3b76;
    background: rgba(19,135,135,.8);
  }

  .light-green {
    //background: #3b3b76;
    background: rgba(90,134,134,.8);
  }

  .steelblue {
    background: rgba(70,130,180,.8);
  }

  .gold {
    //background: #3b3b76;
    background: rgba(202, 130, 0, 0.8);
  }

  .gray {
    //background: #3b3b76;
    background: rgba(135, 135, 135, 0.8);
  }


  .item-title {
    position: absolute;
    //background:lime;
    width:80%;
    left: 50%;
    top: 50%;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    z-index: 1;
    // transition: top .5s ease;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 767px) {
      font-size: 14px;
      width: 100%;
      white-space: pre-wrap;
      padding: 0 5px;
    }
  }

  .item-price {
    z-index:1;
    position:absolute;
    bottom:4px;
    right:8px;
    font-size:1em;
    font-weight:500;
    // color: black;
    color:lightgray;
  }

  .item-open-item  {
    display: block;
    font-size: 1.5em;
    position: absolute;
    left: 10px;
    bottom: 6px;
    text-shadow: 2px 2px 1px black;
    color:green;
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

 .trash-icon {
    z-index:2;      // make sure the user can hit this target.
    display: block;
    font-size: 1.1em;
    position: absolute;
    left: 10px;
    top: 6px;
    text-shadow: 2px 2px 1px black;
    color:rgb(255, 162, 0);
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  .item-qty{
    display: block;
    font-size: 1.5em;
    position: absolute;
    z-index:1;
    right:10px;
    top:6px;
    font-weight:500;
    //color: #8D8741;
    // color: rgba(141,135,65,.7);
    color: lightgray;
    text-shadow: 2px 2px 1px black;
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    height: 120px;
  }

  .item-img {
    position: absolute;
    height: 100%;
    left: 0;
  }

   //.selected:before {
   //  position: absolute;
   //  background: rgba(0,0,0, .3);  // <-- not used??
   //  content: "";
   //  width: 100%;
   //  height: 100%;
   //}


}
  .item-selected {
    background: rgba(0, 0, 0, 1);
  }

  /* --- Styles from previous step --- */
  .max-quantity-reached {
    pointer-events: none;
    opacity: 0.6;
  }
  .max-quantity-reached .trash-icon {
    pointer-events: auto;
    cursor: pointer;
    opacity: 1;
  }
  .show-hand {
    cursor: pointer;
  }
  /* --- End previous styles --- */


  /* Style the new wrapper - give it the position/look the old .item-qty had */
  .qty-wrapper {
    position: absolute; 
    top: 5px;   
    right: 4px;    
    width: 28px;   
    height: 28px;  
    /* background-color: rgba(0, 0, 0, 0.6); */
    /* border-radius: 50%;  */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; 
    color: white;
    font-weight: bold;

    /* background:orange; */

    /* position: relative; */
  }

  /* Style the actual quantity number inside the wrapper */
  .item-qty-display {
    text-align: center;
    font-size: 0.9em; /* Adjust as needed */
    line-height: 1; /* Crucial for centering within flex */
    /* No positioning needed here, handled by wrapper */
    z-index: 1; /* Below the overlay */
  }

  /* Style the MAX overlay */
  .max-qty-overlay {
    position: absolute; /* Position relative to .qty-wrapper */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(200, 0, 0, 0.65); /* Semi-transparent red overlay */
    /* border-radius: inherit; */
    border-radius: 50%;
    padding:.25em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 0.6em; /* Smaller text for "MAX" */
    font-weight: 100;
    text-align: center;
    line-height: 1;
    z-index: 2; /* Ensure it's above the item-qty-display */
    overflow: hidden; /* Hide anything sticking out */
  }

  .max-qty-overlay span {
    /* Optional: specific styles for the text */
    padding: 1px;
  }

  .max-qty-overlay i {
    /* Optional: specific styles for an icon */
    font-size: 1.2em; /* Make icon slightly larger than text */
  }

  /* Ensure qty wrapper is visible even when main container is 'disabled' */
  .max-quantity-reached .qty-wrapper {
    pointer-events: auto; /* Allow hover effects if any */
    opacity: 1; /* Make sure it doesn't fade */
  }

</style>
