<template>
  <div class="container">
    <div class="page-header">
      <img alt="iPOS logo" src="../assets/iPosCafe-Logo.png">
      <h2>iPOS Cafe</h2>
    </div>
    <hr>
    <h3>About Us</h3>
    <p>iPOS Cafe - POS the easy way</p>
    <p v-if="posConfig">For support, please call: 601.806.2500 or email: <a :href="emailHref()">support@ipos.cafe</a></p>
    <br>
    <h4>Version Numbers</h4>
    <ul>
      <li>{{appVersion()}}</li>
      <li>API Version {{posConfig.apiVersion}}</li>
    </ul>

    <div class="footer-section">
      <div>
        <button class="icons-link-mobile text-center"  @click="back()">
          <i class="fas fa-window-close footer-section-icon"></i>
          <div class="footer-section-text">Done</div>
        </button>
      </div>
      <div>
        <button class="icons-link-mobile text-center" disabled @click="nop">
          <i class="footer-section-icon"></i>
          <div class="footer-section-text"></div>
        </button>
      </div>
      <div>
        <button class="icons-link-mobile text-center"  disabled @click="nop">
          <i class="footer-section-icon"></i>
          <div class="footer-section-text"></div>
        </button>
      </div>
      <div>
        <button class="icons-link-mobile text-center" disabled @click="nop">
          <i class="footer-section-icon"></i>
          <div class="footer-section-text"></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about-us',
  data () {
    return {
      posConfig: null
    }
  },
  mounted () {
    this.posConfig = JSON.parse(localStorage.getItem('posConfig')) || {apiVersion:'undefined'};
  },
  methods: {
    nop() {},
    back() {
      this.$router.go(-1)
    },
    emailHref() {
//       let message =`

// ----------[ Please describe the problem above this line ]---------\n

//        Employee Name: ${this.posConfig.employeeName} (${this.posConfig.employeeId})\n
//          App Version: ${this.appVersion()}\n
//          API Version: ${this.posConfig.apiVersion}\n
//          Cashier  ID: ${this.posConfig.cashierId}\n
//          Terminal ID: ${this.posConfig.terminalId}\n
//       Revenue Center: ${this.posConfig.revenueCenter}\n
//                 Site: ${this.posConfig.site}\n
//        Transaction #: ${this.posConfig.transactionNumber}\n
//       Last Update ID: ${this.posConfig.lastUpdateId}\n
//               tenant: ${this.posConfig.workstationConfig.tenant}\n
// `;

      return `mailto:support@ipos.cafe?subject=Support for ${this.posConfig.workstationConfig.title}`;
    },
    appVersion() {
      return document.title + ' v' + this.$root.appVersion;
    }
  }
}
</script>

<style lang="scss">
  .page-header {
    img {
      margin-top: 3em;
    }
  }


.footer-section{
  display: flex;
  justify-content: space-evenly;
  position: fixed !important;
  bottom: 0px !important;
  width: 100% !important;
  padding: 7px 0 3px 0 !important;
  background: #fff !important;
  z-index: 31 !important;
  left: 0;
  .icons-link-mobile {
    text-align: right;
    font-size: 14px;
    color: #659DBD;
    cursor: pointer;
    margin-bottom: 4px;
    background: none;
    border: none;
    outline: none !important;
    &:disabled {
      color: #adcbdc;
    }
  }

  .footer-section-icon{
    font-size: 20px;
  }
  .footer-section-text{
    //font-size: 7px;
    font-size:1em;
  }
}
.footer-section {
    box-shadow: 0px 0px 4px rgba(0,0,0,.2);
}
</style>
