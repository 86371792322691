<template>
  <div class="pos-page">
    <!-- <div class="row mx-0" v-if="!isShowPaymentDialog && !isShowFunctionDialog"> -->

      <div class="col-12 tr-header">
        <div class="trh-status">{{ workstationConfig.title }}</div>
        <div class="trh-employee">{{ prompt.headerEmployee }} {{ posConfig.employeeId }} : {{ posConfig.employeeName }}</div>
        <div class="trh-status"><span>{{ prompt.headerTerminal }} {{ posConfig.terminalId }}/ {{ prompt.headerRevenueCenter }} {{ posConfig.revenueCenter }}</span></div>
        <div class="trh-transaction"><span>{{ prompt.headerTransactionNumber }} {{ transaction.transactionNumber }}</span></div>
        <div class="trh-total">{{ prompt.headerTotal }} <span>{{ currency(transaction.total) }}</span></div>
      </div>
      <div class=" mx-0 pos-page-body"  v-if="!isShowPaymentDialog && !isShowFunctionDialog">

        <!-- Future Item Category Selection for the more custom layout options -->
        <!-- <div style="display:grid;">
          <div v-for="(category, catIndex) in categories.filter(c => { return c.itemType === 'item' })" :key="catIndex" style="">
            <div style="z-index:99999;">{{ category.title.toUpperCase() }} <span v-show="categoryHasItems(category.category) === true"><i class="fa fa-check"></i></span></div>
          </div>
        </div> -->

        <div class="non-printable" >
          <b-tabs
            class=" "
            active-nav-item-class="font-weight-bold text-uppercase "
            active-tab-class="font-weight-bold"
            content-class="pl-2 tab-panel"
            v-model="selectedTab"
            id="navigation-bar"
          >
            <b-tab v-for="(category, catIndex) in categories.filter(c => { return c.itemType === 'item' })" :key="catIndex" @click="categorySetFocus(catIndex)" lazy>
                <template v-slot:title>
                  <div style="z-index:19;">{{ category.title.toUpperCase() }} <span v-show="categoryHasItems(category.category) === true"><i class="fa fa-check"></i></span></div>
                </template>
                <div class="row">
                  <div class="col-12 col-md-7 col-lg-8 col-xl-9 px-0 non-printable left-panel" >
                    <div v-if="loading">Loading ...</div>
                    <div v-if="!loading && category.items" class="content-payne">

                      <numeric-keypad v-if="showNumericKeyboard" title="Enter Item Price" ref="priceKeyboard" @click="keypadEnter($event)" @cancel="showNumericKeyboard = false;" />
                      <standard-keyboard v-if="showStandardKeyboard" title="Enter Reference" ref="referenceKeyboard" @click="keyboardEnter($event)" @cancel="showStandardKeyboard = false;" />

                      <div class="hot-filters" v-if="category.hotFilters && category.hotFilters.length > 0">
                        <b-button class="show-hand item-filter-button" style="background:black;" @click="itemFilters = []">ALL</b-button>
                        <template v-for="(f, hfIndex) in category.hotFilters">
                          <b-button class="show-hand item-filter-button" :key="hfIndex" :style="itemFilters.indexOf(f) >= 0 ? 'background: rgba(202, 130, 0, 0.8);' : ''" @click="setItemFilter(f)">
                            {{ f }}
                          </b-button>
                        </template>
                      </div>
                      <!--                <div class="col-4  col-sm-4 col-md-4 col-lg-3 col-xl-2 pr-2 pl-0 d-inline-block" v-for="(menuItem, index) in availableMenuItems( category )" :key="index">-->
                      <div class="items-container" >
                        <div class="item-container"  v-for="(menuItem, index) in availableMenuItems(category)" :key="index">
                          <ts-menu-item
                            :item="menuItem"
                            @select="selectItem"
                            @removeOne="removeOneMenuItem"
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-12" v-if="!categories[catIndex] && !loading">
                    No Items
                  </div>
                  <div class="col-12 col-md-5 col-lg-4 col-xl-3 px-1 d-none d-sm-none d-md-block d-lg-block d-xl-block right-panel ">
                    <div class="receipt-panel" >
                      <order-receipt class="receipt" :orderDetails="transaction" :showDescription="isShowDescription"  @detail="isShowDescription=!isShowDescription" :sortReceipt="workstationConfig.options.sortReceipt" :enablePrint="workstationConfig.options.receiptPrinterActive" />
                    </div>
                  </div>
                </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>

    <functions-dialog
      :showDialog="isShowFunctionDialog"
      :changeRoute="changeRoute"
      :canSignOut="canSignOut()"
      :options="workstationConfig.options"
      :logo="workstationConfig.propertyLogo"
      @logOut="logOut"
      @reports="reports"
      @done="functionsDone"
    ></functions-dialog>

    <payment-dialog
      :showDialog="isShowPaymentDialog"
      :options="workstationConfig.options"
      :tenderMedia="workstationConfig.tenderMedia"
      :configItems="workstationConfig.configItems"
      :paymentType="workstationConfig.paymentType"
      :transaction="transaction"
      :paymentStatus="paymentStatus"
      :prompt="prompt"
      @resetTransaction="newTransaction"
      @post="postPayment($event)"
      @receipt="printReceipt()"
      @cancel="isShowPaymentDialog=false"
      @home="home()"
    ></payment-dialog>

    <b-modal v-model="isShowReceipt" id="modal-receipt" hide-footer>
      <order-receipt
        :orderDetails="transaction"
        @scrollToBottom1="scrollToBottom"
        :sortReceipt="workstationConfig.options.sortReceipt"
        :enablePrint="workstationConfig.options.receiptPrinterActive"
      />
    </b-modal>

    <div id="footer-actions" class="footer-section">
      <div>
        <button class="icons-link-mobile text-center" @click="cancelSale">
          <i class="fas fa-window-close footer-section-icon"></i>
          <div class="footer-section-text">CANCEL</div>
        </button>
      </div>
      <div v-if="workstationConfig.options.canVoid">
        <button class="icons-link-mobile text-center" :style="voidStyle()" @click="voidToggle">
          <i class="fas fa-window-close footer-section-icon"></i>
          <div class="footer-section-text">Void</div>
        </button>
      </div>
      <div>
        <button class="icons-link-mobile text-center" :disabled="transaction && transaction.items && transaction.items.length === 0" @click="toggleReceipt">
          <i class="fas fa-print footer-section-icon"></i>
          <div class="footer-section-text">View Receipt</div>
        </button>
      </div>
      <div v-if="workstationConfig.options.canFunctions">
        <button class="icons-link-mobile text-center" :style="(isShowFunctionDialog) ? 'text-transform:uppercase;color:coral;' : ''" @click="toggleFunctionsDialog">
          <i class="fas fa-file-invoice-dollar footer-section-icon"></i>
          <div class="footer-section-text">Functions</div>
        </button>
      </div>
      <div>
        <button class="icons-link-mobile text-center" :style="(isShowPaymentDialog) ? 'text-transform:uppercase;color:coral;' : ''" :disabled="transaction && transaction.items && transaction.items.length === 0" @click="pay(transaction)">
          <i class="fas fa-file-invoice-dollar footer-section-icon"></i>
          <div class="footer-section-text">PAY</div>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable */
// import { mapState } from 'vuex'
// import Loader from '@/components/Loader'
import Currency        from 'currency.js'
import Moment          from 'moment'
import GeneralService  from '@/services/GeneralService'
import _               from 'lodash'; // todo: move this to root
import OrderReceipt    from '@/components/OrderReceipt'
import FunctionsDialog from '@/components/FunctionsDialog'
import PaymentDialog   from '@/components/PaymentDialog'
import DAH             from '@/lib/dataAccessHelpers'
import FMT             from '@/lib/formattingLib'
import EPSON           from '@/lib/epsonLib'
import TsMenuItem      from "@/components/TsMenuItem";
import NumericKeypad   from "@/components/NumericKeypad";
import StandardKeyboard from "@/components/StandardKeyboard";
import axios           from 'axios'

export default {
  name      : 'productList',
  components: {
    OrderReceipt,
    FunctionsDialog,
    PaymentDialog,
    TsMenuItem,
    NumericKeypad,
    StandardKeyboard
  },
  data() {
    return {
      isPrinterError      : false,
      isPrinting          : false,
      isShowFunctionDialog: false,
      isShowPaymentDialog : false,
      isShowReceipt       : false,
      isVAT               : false,
      isShowDescription   : true,

      // configuration details
      posConfig           : {},      // the major big configuration item
      workstationConfig   : {},      // the workstation specific portion from inside posConfig.
      prompt              : {},      // the "prompt" object from inside workstation.

      upcNumber: "",

      printerAddress: null,
      orderPrinterIp: '192.168.15.164',     // this gets overwritten when the config comes in

      builder             : null,    // buffer to assemble print data
      showNumericKeyboard : false,
      showStandardKeyboard: false,
      savedItem           : null,

      cart           : [],
      cartTotal      : 0,
      categories     : [],
      consolidateTax : false,
      itemFilters    : [],
      loading        : true,
      selected       : false,
      selectedTab    : 0,
      showDescription: true,    // todo: fit this in if enabled
      transaction    : {},      // look above for the template
      void           : false,

      transactionTemplate : {
        accountNumber    : "",
        badgeNumber      : "",
        workstationNumber: "",
        wksId            : "",
        checkNumber      : 0,
        transactionNumber: 0,
        employeeName     : "",
        employeeNumber   : 0,
        cashierNumber    : 0,
        revenueCenter    : 0,
        revenueCenterName: "",
        receiptHeader    : [],
        receiptFooter    : [],
        checkInfo        : [],
        coverCount       : 1,
        numberItems      : 0,
        taxExempt        : false,
        taxable          : 0,
        nonTaxable       : 0,
        subTotal         : 0,
        discount         : 0,
        serviceCharge    : 0,
        tax              : [ 0, 0, 0, 0 ],
        total            : 0,
        amountPaid       : 0,
        totalDue         : 0,
        items            : []
      },
      paymentStatus       : null
    }
  },
  watch   : {
    selectedTab(tab) {
      // console.log('tab - ', tab)
    },
    isShowReceipt() {
      // console.log('isShowReceipt - ', this.isShowReceipt)
    }
  },
  computed: {

    transactionItemCount() {
      return this.transaction.items.reduce((p, c) => {
        return p + c.qty
      }, 0);
    }
  },
  async created() {
    let t                  = this;
    this.loading           = true;
    this.posConfig         = JSON.parse(localStorage.getItem('posConfig'));  // Need this early
    this.workstationConfig = this.posConfig.workstationConfig;
    this.prompt            = this.workstationConfig.prompt;
    this.upcNumber         = "";

    delete this.posConfig.workstationConfig;    // remove the big object since this is redundant.

    console.table("workstation config = ", this.workstationConfig, this.posConfig);

    await this.getCategories()
    await this.categorySetFocus(0)

    this.taxRates = this.workstationConfig.taxRates;

    if(!this.transaction || !this.transaction.items || this.transaction.items.length === 0) {
      this.clearTransactionValues(); // newTransaction();    // get ready for our first transaction
    } else {
      this.transaction = this.transactionObject
    }
    // console.log( "-----------------------------------calling INIT()" ,this.workstationConfig.printerIp );

    this.orderPrinterIp = this.workstationConfig.primaryPrinter; // fixme: remove all local references and use ONLY the workstationConfig object

    let result = await EPSON.init(this.workstationConfig.primaryPrinter, this.workstationConfig.primaryPrinterName );
    // console.log("epson.init() result = ", result);
    // console.log( "-----------------------------------back from init()");

    window.addEventListener('keypress', this.keyboardHandler);

    this.loading = false
  },
  destroyed() {
    window.removeEventListener('keypress', this.keyboardHandler);
  },
  methods: {
    async cancelPrint() {
      if(await this.$root.confirm("Cancel Printing?")) {
        this.isPrinting = false;
      }
    },

    async getItemByUpc(upc) {
      let found = [];
      for(let i = 0; i < this.categories.length; i++){
        let item = this.categories[ i ].items.find((mi) => { return mi.UPCS.indexOf(upc) >= 0 });
        if(item) {
          found.push(item);
        }
      }

      if(found.length > 0) {
        if(found.length > 1) {
          this.$root.alertMessage("Scanner", "Multiple Items Found: " + upc);
          // console.log("Multiple Items Found", upc);
        }
        this.selectItem(found[ 0 ]);
      } else {
        this.$root.alertMessage("Scanner", "Item Not Found: " + upc);
        // console.log("Item not found", upc);
      }
    },

    async keyboardHandler(e, f) {
      if(this.showNumericKeyboard || this.showStandardKeyboard) {
        return;
      }
      let keyCode = e.keyCode;
      let kypd    = String.fromCharCode(keyCode).toLowerCase();
      // console.log("key = ", e,f,keyCode, kypd);

      if(keyCode == 13) {
        // console.log( "Got a full Number" , this.upcNumber );
        await this.getItemByUpc(this.upcNumber);
        this.upcNumber = "";
        return;
      } else {
        if(kypd == 'a') {
          this.upcNumber = "";
          return;
        }
        this.upcNumber += kypd;
      }
      // a0011110724168

    },
    itemCount(items) {
      let c = 0;
      for(var item of items){
        c += item.qty;
      }
      return c;
    },
    containsElement(filterArray, testString, defaultValue = true) {
      if(filterArray.length == 0) {
        return defaultValue;
      }
      let found = true;
      // console.log( filterArray , testString );
      for(let e of filterArray){
        if(testString.indexOf(e) < 0) {
          found = false;
          break;
        }
      }
      // console.log( found );
      return found;
    },
    availableMenuItems(category) {
      return category.items.filter((i) => {
        return (i.options.active === true && this.containsElement(this.itemFilters, i.title.toLowerCase()) == true);
      })
    },
    setItemFilter(filter) {

      let index = this.itemFilters.indexOf(filter);
      if(index >= 0) {
        this.itemFilters.splice(index, 1);
        return;
      }

      // if( this.posConfig.options.filterPopUp ) {
        this.itemFilters = [];
      // }

      this.itemFilters.push(filter);
    },
    async printReceipt( receipt ) {

      // console.log( "receipt" , receipt );
      // console.log( "transaction.receipt" , this.transaction.receipt );
      //
      // this.$root.alert( "Print Receipt"  );

    },
    currency(c) {
      return Currency(c);
    },
    canSignOut() {
      if(!this.workstationConfig.options.canSignOut) {
        return false;
      }
      if(Object.entries(this.transaction).length === 0 && this.transaction.constructor === Object) {
        return true;
      }
      return this.transaction.items.length === 0  // will likely be other reasons we cannot sign out, so a function is the best interface
    },
    toggleFunctionsDialog() {
      this.voidOff()
      if(!this.isShowPaymentDialog) {
        this.isShowFunctionDialog = !this.isShowFunctionDialog
      }
    },
    toggleReceipt() {
      if(this.isShowReceipt) { // FIXME: refactor to use a property instead of a separate variable to keep track of open/close state
        this.$bvModal.hide('modal-receipt') // because this breaks if you click off the receipt to hide -- now we are out of order
        this.isShowReceipt = false;  // but this works "good enough" for the moment
      } else {
        this.$bvModal.show('modal-receipt')
        this.isShowReceipt = true;
      }
    },
    changeRoute() {
    },
    reports() {
      this.$router.push({name: 'reports'})
    },
    logOut() { // event handler
      localStorage.removeItem('x-access-token')
      this.$router.push({name: 'signout'})
    },
    functionsDone() {
      this.isShowFunctionDialog = false;
    },
    voidStyle() {
      return (this.void) ? "color:red;" : ""
    },
    goMenu() {
      // console.log( "productList->goMenu()");
      return this.$router.push({name: 'homeMenu'});
    },
    async home() {
      // console.log( "hit home()");
      return this.$router.push('/');
    },
    pay(transaction) {
      if(this.isShowFunctionDialog) {
        return
      }
      this.voidOff();
      if(this.isShowPaymentDialog) {
        return;
      }
      this.isShowPaymentDialog = !this.isShowPaymentDialog;
      // console.log( "productList->pay() compelete" );
    },
    categoryHasItems(category) {
      let returnStatus = false;
      if(!this.transaction || !this.transaction.items) {
        return returnStatus;
      }
      this.transaction.items.forEach(item => {
        if(item.category === category) {
          returnStatus = true;
        }
      })
      return returnStatus;
    },
    async getCategories() {  // we get this at login.

      if(!this.categories || this.categories.length === 0) {

        let categoryList = localStorage.getItem("categoryList");
        this.categories  = await DAH.getCategories(categoryList);

        if(!this.categories) {
          this.home();
        }
      }
    },
    async categorySetFocus(index) {
      if(!index) {
        return;
      }

      if(index !== this.selectedTab) {
        this.itemFilters = [];
      }

      if(!this.categories[ index ].items || this.categories[ index ].items.length === 0) {    // we should not really ever have a category with no items -
        this.loading = true;
        try {
          let items = await this.getItems(this.categories[ index ].category);
          items.forEach(i => { i.qty = 0; });
          this.categories[ index ].items = items; // Object.assign( {} , items );
          localStorage.setItem('categories', JSON.stringify(this.categories))
        } catch(error) {
          this.errorMessage = error.message;
          console.log(error.message);
        }
      }
    },
    async getItems(category) {
      try {
        let items = await DAH.getItems(category);
        items.forEach(i => {
          if(!i.sequence && i.sequence != 0) {
            i.sequence = 100;
          }
        });

        if(items) {
          items = _.sortBy(items, [ 'sequence', 'title' ]);
        }

        return items;
      } catch(error) {
        this.errorMessage = error.message;
      }
      return [];
    },

    resetCategoryTotals() {
      this.categories = JSON.parse(localStorage.getItem('categories'));
    },
    clearTransactionValues() {
      this.transaction = this.newTransactionObject();
      this.voidOff();
      this.resetCategoryTotals();
      this.calcTransactionTotal();
      this.paymentStatus       = null;
      this.isShowPaymentDialog = false;
    },
    newTransaction() {  // call this to get a new transaction started;
      this.clearTransactionValues();
       // and the last thing we do is this...
       if( !this.workstationConfig.options.transactionStayDown ) {
        this.home();
      }
    },
    calcTransactionTotal() {
      let trans    = this.transaction;
      let taxRates = this.workstationConfig.taxRates;

      trans.taxable       = 0; // taxable subtotal
      trans.nonTaxable    = 0; // non-taxable subtotal
      trans.subTotal      = 0; // transaction subtotal (taxable+nonTaxable)
      trans.tax           = [ 0, 0, 0, 0 ];  // tax collected (4 itemizers)
      trans.discount      = 0;  // positive number, so subtract from total (not currently calculated)
      trans.serviceCharge = 0;  // total service charges (not currently calculated)
      trans.total         = 0;  // transaction total, including tax
      trans.numberItems   = 0;  // # of items
      trans.amountPaid    = 0;

      trans.items.forEach(i => {
        let category = _.find(this.categories, {category: i.category});

        let isTaxable;
        let isTaxEnabled;

        if(category) {
          isTaxable    = category.itemOptions.taxable || false;
          isTaxEnabled = category.itemOptions.taxableItemizers || false;
        } else {
          isTaxable = isTaxEnabled = false;
        }

        let extendedPrice = Currency(i.price).multiply(Math.abs(i.qty)).value;

        switch(i.itemType){
          case 'item':
            trans.numberItems += Math.abs(i.qty);     // not sure if adding voids into the item count is a good idea - but better than subtracting I guess
            if(isTaxable && !trans.taxExempt) {
              trans.taxable = Currency(trans.taxable).add(extendedPrice).value;
              for(let j = 0; j < 4; j++){
                if(isTaxEnabled[ j ]) {
                  trans.tax[ j ] = Currency(trans.tax[ j ]).add(Currency(extendedPrice).multiply(taxRates[ j ] / 100)).value;
                }
              }
            } else {
              trans.nonTaxable = Currency(trans.nonTaxable).add(extendedPrice).value;
            }
            break;

          case 'service': // service charge can apply to items or trans-total; currently it's transaction level
            trans.serviceCharge = Currency(trans.serviceCharge).add(extendedPrice).value;
            break;

          case 'discount': // can have item and trans-total level discounts (default to transaction level right now)
            trans.discount = Currency(trans.discount).add(extendedPrice).value;
            break;

          case 'coupon': // coupons can have "qualifiers" .e.g, coupon for desert only; right now, just apply
            trans.discount = Currency(trans.discount).add(extendedPrice).value;
            break;

          case 'ticket':
            trans.serviceCharge = Currency(trans.serviceCharge).add(extendedPrice).value;
            break;

          case 'tender':
            trans.amountPaid = Currency(trans.amountPaid).add(extendedPrice).value;
            break;

          default:
            console.log("oops, invalid item type"); // still have to account for the price
            trans.nonTaxable = Currency(trans.nonTaxable).add(extendedPrice).value;
            break;
        }

      })

      trans.subTotal = Currency(trans.taxable).add(trans.nonTaxable).value;
      trans.total    = Currency(trans.subTotal)
        .add(trans.tax[ 0 ])
        .add(trans.tax[ 1 ])
        .add(trans.tax[ 2 ])
        .add(trans.tax[ 3 ])
        .add(trans.serviceCharge)
        .subtract(trans.discount).value;
      trans.totalDue = Currency(trans.total).subtract(trans.amountPaid).value;

      if(trans.numberItems > 0 || trans.total !== 0) {
        sessionStorage.setItem('transaction', JSON.stringify(trans)); // fixme: just testing an idea...
      } else {
        sessionStorage.removeItem('transaction');
      }
    },
    removeOneMenuItem(item) {
      let i = _.findLastIndex(this.transaction.items, {itemNumber: item.itemNumber});
      if(i >= 0) {
        item.qty--;
        this.transaction.items[ i ].qty--;
        if(this.transaction.items[ i ].qty === 0) {
          this.transaction.items.splice(i, 1);
        }
      }
      this.calcTransactionTotal();
    },
    resetQtyFromCategory(item) {
      this.categories.forEach((c) => {
        c.items.forEach((i) => {
          if(i.itemNumber === item.itemNumber) {
            i.qty -= (item.qty === 0) ? 1 : item.qty;
          }
        })
      })
    },
    removeMenuItem(item) {
      let indexList = [];
      this.transaction.items.map((currentItem, index) => {
          // Basic matching conditions
          const basicMatch = currentItem.itemNumber === item.itemNumber && 
                            currentItem.price === item.price;
          
          if (basicMatch) {
              // Reference matching logic
              let referenceMatch = false;
              
              // If both undefined, match
              if (currentItem.reference === undefined && item.reference === undefined) {
                  referenceMatch = true;
              }
              // If only one is undefined, no match
              else if (currentItem.reference === undefined || item.reference === undefined) {
                  referenceMatch = false;
              }
              // If both are strings
              else if (typeof currentItem.reference === 'string' && typeof item.reference === 'string') {
                  referenceMatch = currentItem.reference === item.reference;
              }
              // If both are arrays
              else if (Array.isArray(currentItem.reference) && Array.isArray(item.reference)) {
                  referenceMatch = currentItem.reference.length === item.reference.length && 
                                currentItem.reference.every((ref, i) => ref === item.reference[i]);
              }
              // If types don't match (one string, one array), no match implied by referenceMatch remaining false
              
              if (referenceMatch) {
                  indexList.push(index);
              }
          }
      });

      while(indexList.length > 0) {
          let lastIndex = indexList.pop();
          let lastItemQty = this.transaction.items[lastIndex].qty;
          this.transaction.items.splice(lastIndex, 1);
          this.resetQtyFromCategory(item);
          item.qty = item.qty - lastItemQty;
          this.calcTransactionTotal();
      }
  },
    /*
     Our target receipt when we want the cashier to be able to go 'pick' an order
     obviously NOT the correct spacing, but to give a general idea

     Happy's Hamburgers
     Best Burger In Town
     -------------------------
     January 25,2020 11:22am
     Chk: 0001      Term: 99
     -------------------------

     1 Chicken Dinner 5.75
     1 Sprite         0.75
     1 Employee Meal  3.00
     1 Coke           0.75

     Sub Total	10.25
     Tax	 0.00
     Total	10.25

     */
    keypadEnter(v) {
// console.log( "hit keypadEnter()" , v );
      let tt                    = this.savedItem;
      this.savedItem            = null;
      tt.price[ tt.priceLevel ] = parseFloat(v).toFixed(2);
      this.selectItem(tt);   // this will turn off the keyboard
    },
    keyboardEnter(v) {
// console.log( "hit keyboardEnter()" , v );
      let tt             = this.savedItem;
          this.savedItem = null;

      if( v.length > 0 ) {    // test the inbound string because even an empty string gets a 0 length array.
        let ref = v.trim().split(",").map(r => r.trim());
        tt.reference = ref;
      } else {
        tt.reference = "";
      }
          
      this.selectItem(tt);   // this will turn off the keyboard
    },
    addItemToCart(itemToAdd) {

      let t    = this.transaction;
      let item = Object.assign({}, itemToAdd);

      item.selected = false;    // ensure we add this to the object for the receipt.

      if(item.itemType === 'item') {
        if(this.workstationConfig.options.consolidateItems) {
            const existingItemIndex = t.items.findIndex(x => {
                // Basic matching conditions
                const basicMatch = x.itemNumber === item.itemNumber && 
                                  x.void === item.void && 
                                  x.price === item.price;
                
                if (!basicMatch) return false;
                
                // Reference matching logic
                // If both undefined, consider it a match
                if (x.reference === undefined && item.reference === undefined) {
                    return true;
                }
                
                // If only one is undefined, no match
                if (x.reference === undefined || item.reference === undefined) {
                    return false;
                }
                
                // If reference is a string
                if (typeof x.reference === 'string' && typeof item.reference === 'string') {
                    return x.reference === item.reference;
                }
                
                // If reference is an array
                if (Array.isArray(x.reference) && Array.isArray(item.reference)) {
                    if (x.reference.length !== item.reference.length) return false;
                    return x.reference.every((ref, index) => ref === item.reference[index]);
                }
                
                // If one is array and other is string, no match
                return false;
            });

            if (t.items.length > 0 && existingItemIndex > -1) {
                t.items[existingItemIndex].qty += item.qty;
            } else {
                t.items.push(item);
            }
        } else {
            // let add = false;
            // if(t.items.length > 0) {
            //     let p = t.items[t.items.length - 1];
            //     const basicMatch = p.itemNumber === item.itemNumber && 
            //                     p.void === item.void && 
            //                     p.price === item.price;
                
            //     if (basicMatch) {
            //         // Reference matching logic
            //         // If both undefined, consider it a match
            //         if (p.reference === undefined && item.reference === undefined) {
            //             add = true;
            //         }
            //         // If only one is undefined, no match
            //         else if (p.reference === undefined || item.reference === undefined) {
            //             add = false;
            //         }
            //         // If reference is a string
            //         else if (typeof p.reference === 'string' && typeof item.reference === 'string') {
            //             add = p.reference === item.reference;
            //         }
            //         // If reference is an array
            //         else if (Array.isArray(p.reference) && Array.isArray(item.reference)) {
            //             add = p.reference.length === item.reference.length && 
            //                   p.reference.every((ref, index) => ref === item.reference[index]);
            //         }
            //         // If types don't match (one string, one array), add remains false
            //     }
            // }

            // if(add) {
            //     t.items[t.items.length - 1].qty += item.qty;
            // } else {
                t.items.push(item);
            // }
        }
    } else {
        t.items.push(item);
    }

      this.voidOff();
      this.calcTransactionTotal();
    },
    // isSelected(item) {
    //   return _.find(this.transaction.items, (existingItem) => {
    //       // Check itemNumber first
    //       if (existingItem.itemNumber !== item.itemNumber) {
    //           return false;
    //       }

    //       if( existingItem.price[0] != item.price[0]) {
    //         return false;
    //       }

    //       // Handle reference matching
    //       // If both undefined, match
    //       if (existingItem.reference === undefined && item.reference === undefined) {
    //           return true;
    //       }
          
    //       // If only one is undefined, no match
    //       if (existingItem.reference === undefined || item.reference === undefined) {
    //           return false;
    //       }
          
    //       // If both are strings
    //       if (typeof existingItem.reference === 'string' && typeof item.reference === 'string') {
    //           return existingItem.reference === item.reference;
    //       }
          
    //       // If both are arrays
    //       if (Array.isArray(existingItem.reference) && Array.isArray(item.reference)) {
    //           if (existingItem.reference.length !== item.reference.length) return false;
    //           return existingItem.reference.every((ref, index) => ref === item.reference[index]);
    //       }
          
    //       // If types don't match (one string, one array)
    //       return false;
    //   });
    // },
    proceedToPayment() {
      this.$store.dispatch('updateCart', {transaction: this.transaction, categories: this.categories})
      this.$router.push({name: 'checkout'})
    },
    selectItem(item) {  // this is the CATEGORY item

      // console.log( "hit selectItem()", item );

      if(item.options.openItem) {
        if(!this.showNumericKeyboard) {
          this.savedItem = item; // Object.assign({},item);
          this.showNumericKeyboard = true;
          return;
        }
      }

// console.log( "selectItem() 2" );

      if(item.options.askDescription) {
        if(!this.showStandardKeyboard) {
          this.savedItem = item; // Object.assign({},item);
          this.showStandardKeyboard = true;
          return;
        }
      }

// console.log( "selectItem() 3 ");

      this.showNumericKeyboard = false;
      this.showStandardKeyboard = false;


      if(!item.qty) {    // update the CATEGORY item reference
        item.qty = 0;
      }

      if(!this.void) {   // 1 bump per click
        item.qty++;
      } else {
        item.qty--;
      }

      if(!_.has(item, "priceLevel")) {
        item.priceLevel = 0;
      }

      // item.total = Currency(item.price[0]).multiply(item.qty); // for selection list
      let category = _.find(this.categories, {category: item.category});

      let cartItem = {      // make an item for the cart.
        itemNumber : item.itemNumber,
        // seatNumber : 0,
        sequence   : category.sequence || 999999,
        title      : item.title,
        reference  : item.reference,
        description: item.description,
        category   : item.category,
        options    : item.options,
        priceLevel : item.priceLevel,
        taxable    : category.itemOptions.taxable || false,
        void       : this.void,
        itemType   : item.itemType,
        qty        : (this.void) ? -1 : 1,
        price      : Currency(item.price[ item.priceLevel ]).multiply((this.void) ? -1 : 1).value
      }

// console.log( "hit the end of this trail" );

      this.addItemToCart(cartItem);
      this.scrollToBottom()
    },
    voidOff() {
      this.void = false;
    },
    voidToggle() {
      this.void = !this.void;

      // let c = this.cart;
      // let t = this.transaction;
      // let i = this.transaction.items
      //
      // let cat = this.categories

      // this kind of works, but can't reset the "selection window" with the right qty's
      if(this.void) {
        // let t = this.transaction;
        let selectedList = this.transaction.items.filter((i) => { return i.selected === true })
        if(selectedList.length > 0) {
          selectedList.forEach((i) => {
            this.removeMenuItem(i)
          });
          this.voidOff();
        } else {
        }
      }

    },
    scrollToBottom() {
      this.$emit('scrollToBottom')
    },
    updateCartTotal() {
      this.$store.dispatch('updateCartAmount')
    },
    async cancelSale() {
      let that = this
      if(!that.transaction || that.transaction.amountPaid !== 0) {
        return await this.$root.alert("Cannot cancel after a tender");
      }
      if(that.transaction.items.length === 0) {
        return that.goMenu()
      }
      this.$dialog
          .confirm('Are you sure to cancel this sale?', {okText: "Yes, Cancel", cancelText: "No"})
          .then(function(dialog) {
            that.newTransaction()
          }).catch(function() {
      })
    },
    updateDailyTotals(postingTender) {
      let dt          = JSON.parse(localStorage.getItem('dailyTotals'));
      let tenderTotal = _.find(dt.tender, {id: postingTender.itemNumber});
      if(tenderTotal) {
        tenderTotal.qty++;
        tenderTotal.total = Currency(tenderTotal.total).add((postingTender.price * postingTender.qty));
      } else {
        let tender = {
          id   : postingTender.itemNumber,
          qty  : postingTender.qty,
          title: postingTender.title,
          total: postingTender.price
        }
        dt.tender.push(tender);
      }

      localStorage.setItem('dailyTotals', JSON.stringify(dt))
    },
    orderItems(sort = true) {
      if(sort) {
        return _.orderBy(this.transaction.items, [ 'sequence', 'itemNumber' ], [ 'asc' ])
      } else {
        return this.transaction.items
      }
    },

    makeReceipt() {    // build up a receipt as a single string suitable for the API and also for sending to the Epson TM88v printer

      let receipt    = []
      let orderItems = this.orderItems()
      let trans      = this.transaction
      let prompt     = this.prompt        // text controled by the host config

      if(trans.receiptHeader) {
        for(let i = 0; i < trans.receiptHeader.length; i++){
          receipt.push(FMT.mt1(trans.receiptHeader[ i ]))
        }
      }

      // make check header
      receipt.push(trans.employeeNumber.toString() + " " + trans.employeeName)
      receipt.push("=".repeat(40))
      let guests = "Gst " + ((trans.coverCount === 0) ? ' 1' : trans.coverCount.toString());
      receipt.push(FMT.mt3("Tr " + trans.workstationNumber.toString(), "Chk " + trans.checkNumber, guests))
      receipt.push(FMT.mt1(Moment().format("ddd, DD-MMM-YY hh:mma")))
      receipt.push("=".repeat(40))

      // make body items
      for(let i = 0; i < orderItems.length; i++){

        let item = orderItems[ i ]

        let total = Currency(Math.abs(item.qty) * item.price).multiply(item.itemType === 'tender' ? -1 : 1).value

        if(item.itemType === 'item') {
          receipt.push(FMT.ml3(item.qty, item.title, total))
          if(item.options.openItem === true && item.qty != 1) {
            receipt.push(FMT.ml1(item.qty.toString() + " @ " + item.price.toString()));
          }
        } else {
          receipt.push(FMT.ml2(item.title, total))
        }

        if(item.description) {
          let d = item.description.split('\n')
          for(let i = 0; i < d.length; i++){
            receipt.push(FMT.ml1(" " + d[ i ]))
          }
        }

        if(item.reference) {
          for(let i = 0; i < item.reference.length; i++){
            receipt.push(FMT.ml1(" " + item.reference[ i ]))
          }
        }
      }

      // Make Subtotal lines
      receipt.push("")
      receipt.push(FMT.ml2r("# " + prompt.Items + "(" + trans.numberItems.toString() + ")", ""))
      receipt.push(FMT.ml2r(prompt.subTotal, trans.subTotal))

      if(this.isVAT) {
        receipt.push(FMT.ml2r(prompt.taxItemizer[ 0 ], trans.taxable))
      }

      if(!this.isVAT && this.consolidateTax) {
        receipt.push(FMT.ml2r(prompt.taxItemizer[ 0 ], trans.tax.reduce((a, b) => a + b, 0)))
      }

      if(!this.isVAT && !this.consolidateTax) {
        for(let i = 0; i < 4; i++){
          if(trans.tax[ i ] !== 0) {
            receipt.push(FMT.ml2r(prompt.taxItemizer[ i ], trans.tax[ i ]))
          }
        }
      }

      receipt.push(FMT.ml2r(prompt.Total, trans.total))

      if(trans.amountPaid !== 0) {
        receipt.push(FMT.ml2r(prompt.amountPaid, trans.amountPaid))
        receipt.push(FMT.ml2r(prompt.balanceDue, trans.total - trans.amountPaid))
      }

      if(trans.receiptFooter) {
        for(let i = 0; i < trans.receiptFooter.length; i++){ // let the admin add a blank line in the footer if they want one
          receipt.push(FMT.mt1(trans.receiptFooter[ i ]))
        }
      }

      return receipt;

    },
    async postPayment(item) {

      this.voidOff();

      this.addItemToCart(item)
      this.updateDailyTotals(item);

      if(this.transaction.total - this.transaction.amountPaid > 0) {    // transaction not complete, so don't update receipt
        this.paymentStatus = 'partial';
        return;
      }

      let transactionNumber = localStorage.getItem('nextTransactionNumber') || this.posConfig.transactionNumber;  // fixme: This is temp code until the we include this in the auth message
      localStorage.setItem('nextTransactionNumber', 0 + parseInt(transactionNumber) + 1);

      // todo: udpate customer display
      let receiptPackage;
      let receipt;

      try {
        receipt                  = this.makeReceipt()        // fire a receipt to the API with every posting
        this.transaction.receipt = receipt;

        receiptPackage = {
          transactionDate  : Moment().format("YYYY-MM-DD HH:mm:ss"),
          tenderAmount     : Currency(item.price).multiply(item.qty).value,
          tenderNumber     : item.itemNumber,
          revenueCenter    : this.transaction.revenueCenter,
          workstationNumber: this.transaction.workstationNumber,
          checkNumber      : this.transaction.checkNumber,
          receipt          : receipt.join("\n"),
          transaction      : this.transaction
        }

        let apiResponse = await GeneralService.postReceipt(receiptPackage);
        if(apiResponse.status !== 200) {
          this.paymentStatus = 'failed';
          return
        } else {
          this.paymentStatus = 'success';
          try {

            // console.log( "EPSON.printList" , receipt);


            EPSON.printList(receipt);

          } catch(e) {
            console.log("EPSON.printList Failed", e.message);
          }
        }

      } catch(error) {
        console.log(error.message)
      }

      //-------------------------------------------------------------------------------------[ END ]

    },
    // make a new iPos Transaction Object suitable for posting
    newTransactionObject() {
      let t = Object.assign({}, this.transactionTemplate);

      let header = this.workstationConfig.receiptPrinter.header;
      let footer = this.workstationConfig.receiptPrinter.footer;

      let posConfig = JSON.parse(localStorage.getItem("posConfig"))

      t.receiptFooter     = footer;
      t.receiptHeader     = header;
      t.revenueCenter     = posConfig.revenueCenter;
      t.revenueCenterName = posConfig.propertyName;
      t.workstationNumber = posConfig.terminalId;
      t.wksId             = posConfig.terminalId;
      t.employeeNumber    = posConfig.employeeId;
      t.employeeName      = posConfig.employeeName;
      t.transactionNumber = localStorage.getItem('nextTransactionNumber') || posConfig.transactionNumber;
      t.checkNumber       = localStorage.getItem('nextTransactionNumber') || posConfig.transactionNumber;
      t.cashierNumber     = posConfig.cashierId;
      t.items             = [];

      return t;
    }
  }
}
</script>

<style  lang="scss" >

#app .app-container {
  margin-top: 0px !important;
}

// .nav-menu-content {
//   position: unset !important;
// }

// .nav-menu-fullscreen {
//   position: unset !important;
//   overflow-x: hidden !important;
// }

// .nav-menu {
//   box-shadow: none !important;
// }

.pos-page {
  // position: absolute;     // the page starts here
  // top: 0;
  // background:brown;     // restrict all content to within the visible area
  max-height:100vh;
  overflow:hidden;
}

.pos-page-body {
  height:100%;
}

.nav-tabs {
  @media screen and (max-width: 468px) {
    // position: unset;
    &.nav {
      top: 25px !important;
    }
  }
}

.left-panel {
  height:100%;
  // background:black;
}

// .left-content {
//   height:100%;
//   // background:pink;
// }

.right-panel {
  // background:orange;
}

// .right-content {
//   // background:#06317A;
// }

.tab-panel {
  padding-right:.5em;
}

.content-payne {
  padding: .5em .25em 0 .75em;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  height: 80vh; /* total available height */
}

.hot-filters {
  // display:flex;
  // flex-wrap:wrap;
  padding-left:.5em;
  // background:lightgray;
  // background:magenta;
}

.item-filter-button {
  margin: .25em .25em .25em 0;
  text-transform: uppercase;
  border-radius: .5em !important;
}

// flexgrid layout
// .items-container {
//   margin-top:.5em;
//   width:100%;
//   display: flex;
//   flex-wrap: wrap; /* Change to flex-wrap to wrap items within the container */
//   align-content:flex-start;
//   gap: .35em;
//   overflow-y: auto;
//   height: 78.5vh;
// }

// .item-container {
//   flex: 1 1 143px; /* Adjust flex properties to allow items to grow and shrink within the container */
// }

// -----------------------------------[ begin grid layout ]------
.items-container {
  margin-top: .5em;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(143px, 1fr));
  gap: .35em;
  align-content:flex-start;
  overflow-y: auto;
  flex-grow: 1; /* Allow .items-container to take up the remaining space */
  min-height: 0; /* Allow .items-container to shrink below its content size */
}

.item-container {
  /* No need for flex properties in grid layout */
}
//-------------------------------------[ end grid layout ]-----


//.category-container.container {
//  margin-top: 70px !important;
//  @media screen and (max-width: 468px) {
//    padding-top: 64px !important;
//    margin-top: 0 !important;
//  }
//}

.trh-employee {
  @media screen and (max-width: 468px) {
    display: none;
  }
}

.tr-header {

  // position: fixed ;
  //background: #1363a4;
  background: #06317A;

  // background:#048789;
  color: white;
  display: table;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 30px;
  border-bottom:1px solid white;

  .trh-employee {
    display: table-cell;
    text-align: center;
    padding-left: .5em;
    border-right: 1px solid white;
  }

  .trh-status {
    display: table-cell;
    text-align: center;
    border-right: 1px solid white;
  }

  .trh-transaction {
    display: table-cell;
    text-align: center;
    border-right: 1px solid white;
  }

  .trh-total {
    display: table-cell;
    padding-right: 1em;
    text-align: right;

    span {
      font-weight: 800;
      font-size:1.1em;
    }
  }

}



// .order-link-mobile {
//   text-align: right;
//   font-size: 14px;
//   color: #1363a4;
//   cursor: pointer;
//   margin-bottom: 4px;
//   //background: none;
//   border: none;
//   outline: none !important;

//   &:disabled {
//     color: #ccc;
//   }
// }

.show-hand {
  cursor: pointer;
}

// .product-card {
//   border-radius: 5px;
//   overflow: hidden;
//   position: relative !important;

//   .product-card-sub {
//     height: 58px;
//     width: 100%;
//     //background: #94918f; // <-- not used?
//     // background: #1363a4;
//     overflow: hidden;
//   }

//   .product-card-title {
//     //background: #94918f;  // <-- not used??
//     color: #fff;
//   }
// }

// .add-product-container {
//   display: flex;

//   .add-btn-container {
//     //background: #f5f5f5;
//     justify-content: center;
//     align-items: center;
//     border-radius: 10px;
//     width: 100%;
//     display: flex;

//     .add-btn {
//       font-size: 34px;
//       color: #777;
//     }
//   }
// }


.tab-content > .active {
  outline: none;
}



.receipt-panel {
  margin-right:.75em;
  // height:500px;
  // color:black;
  .receipt {
    height:100%;    
  }
}


.cart-button {
  // background-color: #BC986A !important;
  // border-color: #BC986A !important;
  //border-color:#1363a4;
  border-color: #224D95;
  //background: #1363a4;
  background: #224D95;
}

.footer-section {
  display: flex;
  justify-content: space-evenly;
  position: fixed; // !important;
  bottom: 0px;  //  !important;
  width: 100%;//  !important;
  padding: 7px 0 3px 0;// !important;
  background: #fff;
  //  z-index: 9999999 !important;  // wjs:
  left: 0;

  .icons-link-mobile {
    text-align: right;
    font-size: 14px;
    color: #659DBD;
    cursor: pointer;
    margin-bottom: 4px;
    background: none;
    border: none;
    outline: none; // !important;

    &:disabled {
      color: #adcbdc;
    }
  }

  .footer-section-icon {
    font-size: 20px;
  }

  .footer-section-text {
    //font-size: 7px;
    font-size: 1em;
  }
}

.footer-section {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, .2);
}

/* active category */
.categories-section {
  color: white;
  //font-weight:700;
  background: rgb(112, 147, 206);
  border: none;
}

.nav-tabs {
  width: 100%;

  background: #06317A;
  // border-radius: 1em 0;
  // border-bottom: 0px; // solid #ccc !important;

  .nav-item {
    background: #06317A;
    border-right: 1px solid #ccc;
    border-bottom: 0px;
    outline: none;
    margin-bottom: 0;
    vertical-align: middle;

    .nav-link {
      height: 64px;
      color: #fff;
      outline: none;

      &.active {
        font-weight: 600;
        color: white;
        background: rgb(112, 147, 206);
      }
    }
  }
}

@media (max-width: 640px) {
  .nav-tabs .nav-item {
    .nav-link {
      height: auto;
      font-size: 13px;
      margin-top: 5px;
      padding: 5px 10px;
    }

    &:last-child {
      border: none !important;
    }
  }


  .categories-section {
    // padding-bottom: 14px !important;
    // padding-top: 14px !important;
    // height: 30px !important;
    // border: none !important;
    // border-radius: 0px 0px 0px 0px !important;
    // height: 40px !important;
    // margin-top: -1px !important;
  }
  .footer-section-text {
    font-size: 12px !important;
  }
}
</style>
