/* eslint-disable */
export default  {
  applicationKey: '00000-00000-00000-00000-00132',
  API_KEY:  'AIzaSyAkjzNdK2Mf_O4z6iWHBrgu1ncwcyjyJtM',
  POS_APIURL: (process.env.NODE_ENV === "development") ? 'http://localhost:9040'  : 'https://api.icare.cafe'
  // POS_APIURL: (process.env.NODE_ENV === "development") ? 'http://sandbox.ioihan.com:9040'  : 'http://sandbox.ioihan.com:9040'
  // POS_APIURL: (process.env.NODE_ENV === "development") ? 'http://localhost:9040'  : 'http://sandbox.ioihan.com:9040'

  // NOTE: Heroku this env key the setup
  // POS_APIURL: (process.env.NODE_ENV === "development") ? 'http://localhost:9040'  :  'https://api.icare.cafe'
}
