<template>
  <div v-if="show" fullscreen persistent transition="fade-transition" class="nav-menu-content" >
    <div class="nav-menu nav-menu-fullscreen">
      <div class="menu-card">
       
        <div class="row">
          <div class="col-4">
            <h2 class="ml-3 my-4">Functions</h2>
          </div>
          <div class="col-8">
            <div class="functions-logo pt-3"><img :src="logo" alt=""> </div>
          </div>
        </div>
        <div class="big-spacer"></div>
        <div v-for="(item, index) in mainMenu" :key="index">
          <div class="menu-card-items" @click="menus(item)"><i :class="item.icon" aria-hidden="true"></i> <span class="pl-4">{{item.title}}</span> </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FunctionsDialog',
  props: {
    showDialog: { type: Boolean, required: true, default: false },
    canSignOut: { type: Boolean, default: true },
    options: { type: Object, default: () => {} },
    logo: { type: String, default: () => '' },
    isAdmin: { type: Boolean, default: true },
    changeRoute: { type: Function, required: true }
  },
  data () {
    return {
      mainMenu: [
        { id: 1, title: "Sign Out", action: 'signout', icon: 'fa fa-power-off' },
        { id: 2, title: 'Reload Configuration', action: 'reload', icon: 'fas fa-redo' },
        { id: 3, title: 'Setup', action: 'setup', icon: 'fa fa-cog' },
        { id: 4, title: 'Reports', action: 'reports', icon: 'fa fa-file-alt' }
      ]
    }
  },
  computed: {
    show () {
      return this.showDialog
    }
  },
  methods: {
    menus(item) {
      console.log( "hit menus()");
      if (item.action === 'signout') {
        this.doLogOut()
      }
      if (item.action === 'setup') {
        this.tbd()
      }
      if( item.action === 'reload') {
        this.reload();
      }
      if (item.action === 'reports') {
        if( this.options.canReports ) {
          this.reports()
        }
      }
      if (item.action === 'transactionList') {
        this.tbd()
      }
    },
    async reload() {
      console.log( "Hit reload()");
      if( !await this.$root.confirmYesNo("Reload configuration?")) {
        return;
      }
      this.$root.clearStoredConfiguration();
      await this.$root.getPosConfig();
      await this.$root.alert( "Reload successful");

      this.$router.go(0);
      // vm.$forceUpdate();
      this.$emit( "done" );
    },
    tbd () {
      alert('To be developed')
    },
    toggleDialog () {
      return !this.showDialog
    },
    hideDialog () {
      this.showDialog = false
    },
    doChangeRoute (routeName) {
      this.changeRoute(routeName)
      setTimeout(() => {
        this.hideDialog()
      }, 500)
    },
    reports () {
      this.$emit('reports')
    },
    doLogOut () {
      if (this.canSignOut) {
        this.$emit('logOut')
        this.hideDialog()
      } else {
        alert('You cannot currently sign-out.  Do you have a transaction in progress??')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-menu-content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: .2s cubic-bezier(.25,.8,.25,1);
  width: 100%;
  z-index: 20;
  outline: none;
  background: #fff;
}
.nav-menu {
  -webkit-box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
  box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
  border-radius: 2px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  -webkit-transition: .3s cubic-bezier(.25,.8,.25,1);
  transition: .3s cubic-bezier(.25,.8,.25,1);
  width: 100%;
  z-index: inherit;
  &.nav-menu-fullscreen {
    border-radius: 0;
    margin: 0;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
  }
}
/* These colors are NOT correct -- I just made the something to see how they interact in development -- they need to be made correct */
  .standard-button {
    color: white;
    width:30%;
    padding:1em;
    background: salmon;
    margin-left:auto;
    margin-right:auto;
    margin-top:4px;
    border-radius:1em;
  }

 .function-button {
    width:94%;
    font-size:1em;;
    border-radius:1em;
    background: coral;
    padding:1em;
    margin:1em;
  }

.menu-card {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  .menu-card-items{
    padding-left: 24px;
    padding-top: 17px;
    padding-bottom: 17px;
    font-size: 20px;
    border-bottom: 0.5px solid gray;
    cursor:pointer;
  }
  .menu-card-items:hover {
    background: #fbeec1;
  }
  flex-direction: column;
  & .menu-card__text {
    padding: 16px;
    width: 100%;
  }

  & .big-spacer {
    width: 100%;
    height: 10px;
    background: #193628;
    mix-blend-mode: normal;
    opacity: 0.1;
  }

  & .xbig-spacer {
    width: 100%;
    height: 100px;
  }

  & .menu-title {
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #193628;
    margin-top: 18px;
    margin-bottom: 50px;
  }

  & .menu-item {
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #3F464D;
    padding: 15px 0px;
    border-bottom: solid 1px #19362821;
    cursor: pointer;
  }

  & .menu-item-2 {
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #3F464D;
    padding: 15px 0px;
    border-top: solid 1px #19362821;
    cursor: pointer;
  }

  & .sign-out {
    color: red;
  }

}
.functions-logo {
  width: 110px;
  padding: 10px;
  img {
    max-height: 50px;
  }
}
</style>
